import React, { useState, useEffect } from 'react';
import SpotifyWebApi from 'spotify-web-api-node';
import spotifyLogo from './img/spotify-logo.png';

const spotifyApi = new SpotifyWebApi(); 

//#############################################################################################################

function GenerateRec({ song1, song2 }) {
  const [audioFeatures1, setAudioFeatures1] = useState(null);
  const [audioFeatures2, setAudioFeatures2] = useState(null);
  const [averageFeatures, setAverageFeatures] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [topRecommendation, setTopRecommendation] = useState(null);
  const [similarityScore, setSimilarityScore] = useState(null);
  const [showAudioFeatures, setShowAudioFeatures] = useState(false);
  const [topRecAudioFeatures, setTopRecAudioFeatures] = useState(null);

  const [accessToken, setAccessToken] = useState(null);
  const [tokenExpiry, setTokenExpiry] = useState(null);

//###################################################---AUTHORIZES---#########################################################
  const authorize = async () => {
    const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
    const credentials = btoa(`${clientId}:${clientSecret}`);

    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${credentials}`,
      },
      body: 'grant_type=client_credentials',
    });

    const data = await response.json();
    const expiry = new Date().getTime() + (data.expires_in * 1000); // Convert to milliseconds
    setAccessToken(data.access_token);
    setTokenExpiry(expiry);
  };
  const ensureValidToken = async () => {
    const isValidToken = new Date().getTime() < tokenExpiry;

    if (!isValidToken) {
      await authorize();
    }
    
    spotifyApi.setAccessToken(accessToken);
  };

//###################################################---Calculates Average Features---#########################################################
  const calculateAverageFeatures = () => {
    if (!audioFeatures1 || !audioFeatures2) return;
    const keys = Object.keys(audioFeatures1).filter(k => typeof audioFeatures1[k] === 'number');
    const averageFeatures = keys.reduce((acc, key) => {
      acc[key] = (audioFeatures1[key] + audioFeatures2[key]) / 2;
      return acc;
    }, {});
    setAverageFeatures(averageFeatures);
  };

  useEffect(() => {
    const fetchAudioFeatures = async () => {
      await ensureValidToken();
      if (song1) {
        spotifyApi.getAudioFeaturesForTrack(song1.id)
          .then(data => {
            setAudioFeatures1(data.body);
          });
      }
      if (song2) {
        spotifyApi.getAudioFeaturesForTrack(song2.id)
          .then(data => {
            setAudioFeatures2(data.body);
          });
      }
    };
    fetchAudioFeatures();
  }, [song1, song2]);

  useEffect(() => {
    calculateAverageFeatures();
  }, [audioFeatures1, audioFeatures2]);

  //###################################################---Gets Recommendations---#########################################################
  const getRecommendations = async () => {
    if (!averageFeatures || !song1 || !song2) return;//checks that the average features and songs are not null

    let recommendationsList = [];

    // If song1 and song2 are the same, set the top recommendation to that song
    if (song1.id === song2.id) {
      setTopRecommendation(song1);
      recommendationsList.push(song1);
    } else {
        const options = {
          seed_tracks: [song1.id, song2.id],
          target_danceability: averageFeatures.danceability,
          target_energy: averageFeatures.energy,
          target_loudness: averageFeatures.loudness,
          target_speechiness: averageFeatures.speechiness,
          target_acousticness: averageFeatures.acousticness,
          target_tempo: averageFeatures.tempo,
          target_valence: averageFeatures.valence,
          target_instrumentalness: averageFeatures.instrumentalness,
          limit: 10
        };

        await ensureValidToken();
        const data = await spotifyApi.getRecommendations(options);
        recommendationsList = data.body.tracks.filter(track => track.id !== song1.id && track.id !== song2.id);
    }

    setRecommendations({ tracks: recommendationsList }); // Store the recommendations list
};


  useEffect(() => {
    getRecommendations();
  }, [averageFeatures]);

  //######################################################---Sets top track and similarity score--#######################################################


    // Calculate and set the top recommendation and similarity score
    // when the recommendations are updated
  useEffect(() => {
    const fetchTopRecFeatures = async () => {
        if (recommendations) {
            const topRec = recommendations.tracks[0];
            setTopRecommendation(topRec);

            await ensureValidToken();
            spotifyApi.getAudioFeaturesForTrack(topRec.id).then(data => {
                const recFeatures = data.body;
                setTopRecAudioFeatures(recFeatures);  // Set the audio features for the top recommendation

                const danceSimilarity = 1 - Math.abs(recFeatures.danceability - averageFeatures.danceability);
                const nrgSimilarity = 1 - Math.abs(recFeatures.energy - averageFeatures.energy);
                const loudnessSimilarity = 1 - Math.abs(recFeatures.loudness - averageFeatures.loudness);
                const speechSimilarity = 1 - Math.abs(recFeatures.speechiness - averageFeatures.speechiness);
                const acousticnessSimilarity = 1 - Math.abs(recFeatures.acousticness - averageFeatures.acousticness);
                const instrumentalnessSimilarity = 1 - Math.abs(recFeatures.instrumentalness - averageFeatures.instrumentalness);
                const livenessSimilarity = 1 - Math.abs(recFeatures.liveness - averageFeatures.liveness);
                const valenceSimilarity = 1 - Math.abs(recFeatures.valence - averageFeatures.valence);
                const sum = danceSimilarity + nrgSimilarity + loudnessSimilarity + speechSimilarity + acousticnessSimilarity + instrumentalnessSimilarity + livenessSimilarity + valenceSimilarity;
                const score = (sum / 8) * 100;
                setSimilarityScore(score.toFixed(2)); // Keep only 2 decimals
            });
        }
    };

    fetchTopRecFeatures();
}, [recommendations]);


  return (
    <>
      {topRecommendation && (
        <div>
          
          <h2 style = {{textAlign: 'center', margin: '7vh 21vw 0 21vw', borderRadius: '25px 25px 0 0', padding: '1vw', backgroundColor: 'rgba(41, 57, 66, .5)', color: 'white'}}>Top Recommendation:</h2>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px',
            margin: '0 10vw 0 10vw',
            height: '150px',
            backgroundColor: 'rgba(41, 57, 66, .9)',
            borderRadius: '25px',
          }}>
            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 2 }}> {/* This is the container for the album cover and song details */}
              <img src={topRecommendation.album.images[0].url} alt="Album Cover" style={{ width: '100px', height: '100px', marginLeft: '30px', borderRadius: '5px' }} />
              <div style={{ marginLeft: '30px' }}>
                <h3 style={{ fontWeight: 'bold', color: 'white' }}>{topRecommendation.name}</h3>
                <p style={{ color: 'white' }}>{topRecommendation.artists[0].name}</p>
                <p style={{ color: 'white' }}><em>Similarity Score: {similarityScore}%</em></p>
              </div>
            </div>


          </div>
          <div style = {{textAlign: 'center', margin: '0 21vw 0 21vw', borderRadius: '0 0 25px 25px', padding: '1vh', paddingBottom: '3vh', backgroundColor: 'rgba(41, 57, 66, .5)', color: 'white'}}>
            <h3>Listen on Spotify:</h3>
            <button 
              onClick={() => window.open(topRecommendation.external_urls.spotify, "_blank")}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                textAlign: 'center',
              }}
            > 
              <img src={spotifyLogo} alt="Spotify" style={{ width: '100px' }} />
            </button></div>
          <div style = {{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '7vh 10vw 10px 10vw'}}>
            <button 
              style = {{color: 'white', textAlign: 'center', cursor: 'pointer', fontweight: 'bold', border: 'none', backgroundColor: 'rgb(121, 113, 233)', padding: '20px', borderRadius: '10px'}}
              onClick={() => setShowAudioFeatures(!showAudioFeatures)}>
                See the audio features
            </button>
          </div>
          {/* This sets the information that the button displays */}

          {showAudioFeatures && (
            <div style={{  
              alignItems: 'center',
              padding: '10px',
              margin: '2vw 10vw 10px 10vw',
              backgroundColor: 'rgba(41, 57, 66, .9)',
              borderRadius: '25px', 
              textAlign: 'center',
              color: 'white'}}>
              <h3>Danceability (How easy a song is to dance to):</h3>
              <p>Average: {(100 * averageFeatures.danceability).toFixed(2)}% vs. Recommended: {(100 * topRecAudioFeatures.danceability).toFixed(2)}%</p>
              <h3>Energy:</h3>
              <p>Average: {(100 * averageFeatures.energy).toFixed(2)}% vs. Recommended: {(100 * topRecAudioFeatures.energy).toFixed(2)}%</p>
              <h3>Speechiness (% of song with spoken words/speeches):</h3>
              <p>Average: {(100 * averageFeatures.speechiness).toFixed(2)}% vs. Recommended: {(100 * topRecAudioFeatures.speechiness).toFixed(2)}%</p>
              <h3>Acousticness:</h3>
              <p>Average: {(100 * averageFeatures.acousticness).toFixed(2)}% vs. Recommended: {(100 * topRecAudioFeatures.acousticness).toFixed(2)}%</p>
              <h3>Liveness:</h3>
              <p>Average: {(100 * averageFeatures.liveness).toFixed(2)}% vs. Recommended: {(100 * topRecAudioFeatures.liveness).toFixed(2)}%</p>
              <h3>Valence (Happiness):</h3>
              <p>Average: {(100 * averageFeatures.valence).toFixed(2)}% vs. Recommended: {(100 * topRecAudioFeatures.valence).toFixed(2)}%</p>
              <h3>Tempo:</h3>
              <p>Average: {averageFeatures.tempo.toFixed(2)} BPM vs. Recommended: {100 * topRecAudioFeatures.tempo.toFixed(2)/100} BPM</p>
            </div>
          )}
        </div>
      )}
    </>
  );
  

}



export default GenerateRec;

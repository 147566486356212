import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import SpotifyWebApi from 'spotify-web-api-node';
import GenerateRec from './GenerateRec';


const spotifyApi = new SpotifyWebApi();

//###################################################---AUTHORIZES---#########################################################

function useSpotifyAuthorization() {
  const [accessToken, setAccessToken] = useState(null);
  const [tokenExpiry, setTokenExpiry] = useState(null);

  const authorize = async () => {
    const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
    const credentials = btoa(`${clientId}:${clientSecret}`);

      const response = await fetch('https://accounts.spotify.com/api/token', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Basic ${credentials}`,
          },
          body: 'grant_type=client_credentials',
      });

      const data = await response.json();
      const expiry = new Date().getTime() + (data.expires_in * 1000); // Convert to milliseconds
      setAccessToken(data.access_token);
      setTokenExpiry(expiry);
  };

  useEffect(() => {
      if (!accessToken || new Date().getTime() > tokenExpiry) {
          authorize();
      }
  }, []);

  return accessToken;
}
//#############################################################################################################



function SpotifySearch(){
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedSongs, setSelectedSongs] = useState([null, null]); // New state for selected songs
  const [selectedBox, setSelectedBox] = useState(0); // New state for selected box
  const [inputValue, setInputValue] = useState(''); // New state for input value
  const [selectKey, setSelectKey] = useState(0);
  

//###################################################---AUTHORIZES---#########################################################
const accessToken = useSpotifyAuthorization();
    
useEffect(() => {
    if (accessToken) {
        spotifyApi.setAccessToken(accessToken);
    }
}, [accessToken]);

//#############################################################################################################


const handleSearch = (inputValue, actionMeta) => {
  // If the action is a delete/backspace and the search bar is empty and a song box is selected
  if ((actionMeta.action === 'input-action' || actionMeta.action === 'input-blur') && !inputValue && selectedSongs[selectedBox]) {
    return;
  }

  setInputValue(inputValue); // Update input value state
  if (inputValue.trim() !== '') {
    setSearchTerm(inputValue);

    if(inputValue.includes("big p")||inputValue.includes("big n")){
      spotifyApi.searchTracks(encodeURIComponent(inputValue), {popularity: 'true'}).then((data) => {
        setSearchResults(data.body.tracks.items);
      });
    }else{
      spotifyApi.searchTracks(inputValue, {popularity: 'true'}).then((data) => {
        const sortedResults = data.body.tracks.items.sort((a,b) => b.popularity - a.popularity);
        setSearchResults(sortedResults)
      });

    }
  } else {
    setSearchResults([]);
  }
};

const handleSongSelect = (selectedOption) => {
  const newSelectedSongs = [...selectedSongs];
  newSelectedSongs[selectedBox] = selectedOption;
  setSelectedSongs(newSelectedSongs);

  setSelectedBox((selectedBox + 1) % 2);

  // Increment key to force Select to remount
  setSelectKey(selectKey + 1);
};

  const formatOptionLabel = (option) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={option.album.images[0].url} alt="Album Cover" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
      <div>
        <div style={{ fontWeight: 'bold' }}>{option.name}</div>
        <div>{option.artists.map((artist) => artist.name).join(', ')}</div>
      </div>
    </div>
  );



  const handleBoxSelect = (boxIndex) => {
    setSelectedBox(boxIndex);
  };


  return (
    <div>
      <div style = {{margin: '0 20vw 0 20vw'}}>
        <Select
          key={selectKey} // Use key to force remount
          inputValue={inputValue} // Use inputValue state instead of searchTerm
          onInputChange={(value, action) => handleSearch(value, action)}
          options={searchResults}
          getOptionLabel={(option) => option.name}
          formatOptionLabel={formatOptionLabel}
          placeholder="Search for a song"
          isClearable
          onChange={handleSongSelect}
        />
      </div>
      <div style = {{textAlign: 'center', margin: '4vw 10vw 0 10vw', borderRadius: '25px', padding: '1vw', backgroundColor: 'rgba(41, 57, 66, .5)'}}><h2 style = {{color: 'white'}}>Selected Songs:</h2>
      {selectedSongs.map((selectedSong, index) => (
        
        <div
          key={index}
          onClick={() => handleBoxSelect(index)}
          style={{
            padding: '10px',
            margin: '20px 3vw 20px 3vw',
            opacity: selectedBox === index ? '100%' : '80%',
            height: '150px', // Set a fixed height for the boxes
            display: 'flex',
            backgroundColor: 'rgba(41, 57, 66, .9)',
            borderRadius: '25px',
          }}
        >
          {selectedSong ? (
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px', textAlign: 'left' }}>
              <img src={selectedSong.album.images[0].url} alt="Album Cover" style={{ width: '100px', height: '100px', marginRight: '35px', borderRadius : '5px' }} />
              <div>
                  <h3 style={{ fontWeight: 'bold', color: 'white', textAlign: 'left' }}>{selectedSong.name}</h3>
                  <p style = {{color: 'white', textAlign: 'left'}}>{selectedSong.artists.map((artist) => artist.name).join(', ')}</p>
              </div>
          </div>
      ) : (
          <p style={{ display: 'flex', alignItems: 'center', color: 'white' , marginLeft: '30px', textAlign: 'left'}}>Select Song</p> // Placeholder text
      )}

        </div>
      ))}
      </div>
      
      <GenerateRec song1={selectedSongs[0]} song2={selectedSongs[1]} />
    </div>
  );
};
export default SpotifySearch;





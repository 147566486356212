import React from 'react';

function Footer() {
  return (
    <div style={{ 
        padding: '0.5%', 
        backgroundColor: 'rgba(51, 51, 51, 0.8)', 
        color: '#fff',   
        width: '100%', 
        textAlign: 'center', // Pushes the footer down by the height of the background image
    }}>
      <p>Thank you for using TuneMash! If you found it useful or liked it, consider supporting us by following our Github pages:</p>
      <a href="https://github.com/denged1" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px', color: '#fff', textDecoration: 'underline' }}>
        Eddy's Github
      </a>
      <a href="https://github.com/jasmith55" target="_blank" rel="noopener noreferrer" style={{ color: '#fff', textDecoration: 'underline' }}>
        Jake's Github
      </a>
    </div>
  );
}

export default Footer;

import logo from './logo.svg';
import './App.css';
import SpotifySearch from './SpotifySearch';
import background from "./img/bckgrnd.svg";
import logoImg from "./img/tmlogo.svg";
import txtImg from "./img/tmtext.png";
import rect from "./img/rect.svg";
import {useRef} from 'react';
import Footer from './Footer'; 

function App() {
  const windowSize = useRef([window.innerWidth, window.innerheight]);
  return (
    <div>
      <div style = {{position: 'absolute', marginTop: '250vh', width: '100%'}}>
      <Footer />
      </div>
    <div style={{ 
      height: '250vh',
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center', 
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}>
      <div style={{
        height: '15vh',
        backgroundImage: `url(${rect})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}>
        <img src = {logoImg} style = {{
          height: '15vh',
          position: 'relative',
          display: 'inline'
        }}/>
      </div>
      <div style = {{
        height: '10vh'
      }}></div>
      <SpotifySearch />
      </div>
      </div>
  );
}

export default App;
